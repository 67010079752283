import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import PageTitle from "../components/pageTitle"
import HeroCard from "../components/heroCard"
import Value from "../components/value"
import GetInTouch from "../components/getInTouch"

const IndexPage = props => (
  <Layout location={props.location}>
    <section className="hero">
      <div className="container">
        <PageTitle
          title="The Roofing Specialists"
          subtitle="Expert professional roofing services across Surrey &amp; the South Of England"
        />
        <div className="hero-services">
          <HeroCard
            image={props.data.imageHeroOne}
            title="Residential Roofing &amp; Re-Roofing"
          />
          <HeroCard
            image={props.data.imageHeroTwo}
            title="Grade I &amp; II Listed Buildings"
          />
          <HeroCard
            image={props.data.imageHeroThree}
            title="New Builds &amp; Developments"
          />
          <HeroCard
            image={props.data.imageHeroFour}
            title="Church Roof Works"
          />
        </div>
      </div>
    </section>

    <section className="intro">
      <div className="container-sm">
        <h2 className="intro-title">For all your roofing needs</h2>
        <p className="medium">
          C.D. Carter & Co are one of the South of England's most respected
          roofing companies. Based in Surrey, we offer all aspects of roofing
          services from roof repairs to complete re-roofing projects.
          Specialists in periodic Grade I & II listed buildings we have the
          power and experience to take on any job, regardless of size.
        </p>
      </div>
    </section>

    <section className="values">
      <Value
        image={props.data.imageValueOne}
        title="Customer delight"
        text="We understand how valuable your home is to you; that is why we are dedicated to producing nothing but the very best in quality and affordability."
      />
      <Value
        image={props.data.imageValueTwo}
        title="Quality service"
        text="Highly rated and recommended. We have amassed a proven track record and a long list of satisfied clients since the company was founded back in early 2004."
      />
      <Value
        image={props.data.imageValueThree}
        title="Diocese approved contractor"
        text="Approved Diocese specialist for all Church roof works. Expert roofers employed by us provide professional and reliable roofing services to all our customers."
      />
    </section>

    <GetInTouch
      title="Want to work with us?"
      text="Whether you're starting a new project, looking for repairs or interested in a free estimate we can’t wait to hear about it and find the best way for us to help you with it."
    />
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query HomepageQuery {
    imageHeroOne: file(
      relativePath: { eq: "hero-card/roofing-re-roofing.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          width: 608
          height: 384
          placeholder: BLURRED
          quality: 60
          formats: [AUTO, WEBP]
        )
      }
    }
    imageHeroTwo: file(relativePath: { eq: "hero-card/listed-building.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          width: 608
          height: 384
          placeholder: BLURRED
          quality: 60
          formats: [AUTO, WEBP]
        )
      }
    }
    imageHeroThree: file(relativePath: { eq: "hero-card/new-builds.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          width: 608
          height: 384
          placeholder: BLURRED
          quality: 60
          formats: [AUTO, WEBP]
        )
      }
    }
    imageHeroFour: file(relativePath: { eq: "hero-card/churches.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          width: 608
          height: 384
          placeholder: BLURRED
          quality: 60
          formats: [AUTO, WEBP]
        )
      }
    }
    imageValueOne: file(relativePath: { eq: "values/customer-delight.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 720
          placeholder: BLURRED
          quality: 60
          formats: [AUTO, WEBP]
        )
      }
    }
    imageValueTwo: file(relativePath: { eq: "values/quality-service.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 720
          placeholder: BLURRED
          quality: 60
          formats: [AUTO, WEBP]
        )
      }
    }
    imageValueThree: file(
      relativePath: { eq: "values/approved-contractor.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 720
          placeholder: BLURRED
          quality: 60
          formats: [AUTO, WEBP]
        )
      }
    }
  }
`
