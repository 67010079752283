import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const HeroCard = props => {
  const image = getImage(props.image)

  return (
    <div className="hero-card">
      <div className="hero-card-image">
        <GatsbyImage image={image} alt={props.title} />
      </div>
      <h4 className="card-title">{props.title}</h4>
    </div>
  )
}

export default HeroCard
