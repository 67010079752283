import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Value = props => {
  const image = getImage(props.image)

  return (
    <div className="value">
      <div className="value-image">
        <GatsbyImage image={image} alt={props.title} />
      </div>

      <div className="value-copy">
        <div className="value-copy-inner">
          <h2 className="value-title">{props.title}</h2>
          <p>{props.text}</p>
        </div>
      </div>
    </div>
  )
}

export default Value
